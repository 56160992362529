import GlobalErrorHandler from "@/Custom/GlobalErrorHandler";
import Layout from "@/components/layouts";
import Theme from "@/components/template/Theme";
import appConfig from "@/configs/app.config";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import "./locales";
import mockServer from "./mock";
import store, { persistor } from "./store";
import { BusinessProvider } from "./Custom/BusinessContext";
import AnalyticsScripts from "./Custom/AnalyticsScripts";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const environment = process.env.NODE_ENV;

/**
 * Set enableMock(Default false) to true at configs/app.config.js
 * If you wish to enable mock api
 */
if (environment !== "production" && appConfig.enableMock) {
	mockServer({ environment });
}

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			refetchOnWindowFocus: false,
		},
	},
});

function App() {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<QueryClientProvider client={queryClient}>
					<BrowserRouter>
						<Theme>
							<BusinessProvider>
								<GlobalErrorHandler>
									<AnalyticsScripts />
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<Layout />
									</LocalizationProvider>
								</GlobalErrorHandler>
							</BusinessProvider>
						</Theme>
					</BrowserRouter>
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</PersistGate>
		</Provider>
	);
}

export default App;
