import ApiService from "./ApiService";
import { API_PATHS } from "@/constants/api.constant";
import axios from "axios";

// This dons't work with authorization token that's why using it directly with axios
export async function uploadFileToS3(file, signedUrlData, onUploadProgress) {
	const formData = new FormData();

	// Append the fields from the response to the form data
	for (const [key, value] of Object.entries(signedUrlData.fields)) {
		formData.append(key, value);
	}

	// Append the file
	formData.append("file", file);

	return axios.post(signedUrlData.url, formData, {
		onUploadProgress: (progressEvent) => {
			const percentage = Math.round(
				(progressEvent.loaded * 100) / progressEvent.total,
			);
			onUploadProgress(percentage);
		},
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
}

export async function getSignedUploadUrl(
	business,
	file_mime_type,
	file_extension,
) {
	return ApiService.fetchData({
		url: `${API_PATHS.generateS3UploadUrl}?business=${business}&type=${file_mime_type}&file_type=${file_extension}`,
		method: "get",
		data: null,
	});
}

export async function getIpAddress(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.ipAddress + url,
		method,
		data,
	});
}
export async function getCurrency(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.currency + url,
		method,
		data,
	});
}
export async function getCountries(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.country + url,
		method,
		data,
	});
}
export async function getCity(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.city + url,
		method,
		data,
	});
}
export async function getLanguages(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.language + url,
		method,
		data,
	});
}

export async function apiUserManagement(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.userManagement + url,
		method,
		data,
	});
}
export async function domainCheck(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.domainCheck + url,
		method,
		data,
	});
}
export async function apiBusiness(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.business + url,
		method,
		data,
	});
}
export async function apiDashboard(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.dashboard + url,
		method,
		data,
	});
}
export async function apiVenue(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.venue + url,
		method,
		data,
	});
}
export async function apiBooking({
	url = "",
	method = "get",
	data = null,
	responseType,
}) {
	return ApiService.fetchData({
		url: API_PATHS.booking + url,
		method,
		data,
		responseType, // blob or json
	});
}
export async function apiRedeem(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.redeem + url,
		method,
		data,
	});
}
export async function apiBranding(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.branding + url,
		method,
		data,
	});
}
export async function apiSession(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.session + url,
		method,
		data,
	});
}
export async function apiTicket(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.ticket + url,
		method,
		data,
	});
}
export async function apiDiscount(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.discount + url,
		method,
		data,
	});
}
export async function apiEvent(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.event + url,
		method,
		data,
	});
}
export async function apiSocial(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.social + url,
		method,
		data,
	});
}
export async function apiIntegration(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.integration + url,
		method,
		data,
	});
}
export async function apiIntegrated(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.integrated + url,
		method,
		data,
	});
}
export async function apiStripe(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.stripe + url,
		method,
		data,
	});
}
export async function apiMedia(
	url = "",
	method = "get",
	data = null,
	type = "default",
) {
	return ApiService.fetchData({
		url: (type === "sort" ? API_PATHS.mediaSort : API_PATHS.media) + url,
		method,
		data,
	});
}
export async function apiServiceCharge(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.serviceCharge + url,
		method,
		data,
	});
}
export async function apiEventTicket(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.eventTicket + url,
		method,
		data,
	});
}
export async function apiCoupon(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.coupon + url,
		method,
		data,
	});
}
export async function apiCrm({
	url = "",
	method = "get",
	data = null,
	responseType, // blob or json
}) {
	return ApiService.fetchData({
		url: API_PATHS.crm + url,
		method,
		data,
		responseType,
	});
}
export async function apiReports(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.reports + url,
		method,
		data,
	});
}
export async function apiNotification(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.notification + url,
		method,
		data,
	});
}
export async function apiDownloadBooking({
	url = "",
	method = "get",
	data = null,
	// Its important to set the responseType:'blob'.
	responseType = "blob",
}) {
	return ApiService.fetchData({
		url: API_PATHS.downloadBooking + url,
		method,
		data,
		responseType,
	});
}
export async function apiOwnership(url = "", method = "get", data = null) {
	return ApiService.fetchData({
		url: API_PATHS.ownership + url,
		method,
		data,
	});
}
export async function apiResendBooking({
	url = "",
	method = "get",
	data = null,
}) {
	return ApiService.fetchData({
		url: API_PATHS.resendBooking + url,
		method,
		data,
	});
}
