import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	filters: {
		firstBookedOn: "",
		lastBookedOn: "",
	},
};

export const crmSlice = createSlice({
	name: "crm",
	initialState,
	reducers: {
		setCrmFilters: (state, action) => {
			state.filters = action.payload;
		},
		resetCrmFilters: (state) => {
			state.filters = initialState.filters;
		},
	},
});

// Export the actions
export const { setCrmFilters, resetCrmFilters } = crmSlice.actions;

// Export the reducer
export default crmSlice.reducer;
