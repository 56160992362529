import {
	combineReducers,
	type CombinedState,
	type AnyAction,
	type Reducer,
} from "@reduxjs/toolkit";
import auth, { type AuthState } from "./slices/auth";
import base, { type BaseState } from "./slices/base";
import locale, { type LocaleState } from "./slices/locale/localeSlice";
import theme, { type ThemeState } from "./slices/theme/themeSlice";
import error from "./slices/error/errorSlice";
import business from "./slices/business/businessDataSlice";
import booking from "./slices/booking/bookingSlice";
import crm from "./slices/crm/crmSlice";
import dashboard from "./slices/dashboard/dashboardSlice";
import event from "./slices/event/eventSlice";
import RtkQueryService from "@/services/RtkQueryService";

export type RootState = CombinedState<{
	auth: CombinedState<AuthState>;
	base: CombinedState<BaseState>;
	locale: LocaleState;
	theme: ThemeState;
	/* eslint-disable @typescript-eslint/no-explicit-any */
	[RtkQueryService.reducerPath]: any;
}>;

export interface AsyncReducers {
	[key: string]: Reducer<any, AnyAction>;
}

const staticReducers = {
	auth,
	base,
	locale,
	theme,
	error,
	business,
	booking,
	crm,
	dashboard,
	event,
	[RtkQueryService.reducerPath]: RtkQueryService.reducer,
};

const rootReducer =
	(asyncReducers?: AsyncReducers) => (state: RootState, action: AnyAction) => {
		const combinedReducer = combineReducers({
			...staticReducers,
			...asyncReducers,
		});
		return combinedReducer(state, action);
	};

export default rootReducer;
